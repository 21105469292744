import Form from 'react-bootstrap/Form';
import { IPickup } from '../../types/FundraiserTypes.ts';
import { FormikErrors, FormikTouched, getIn } from 'formik';
import { ChangeEvent } from 'react';
import moment from 'moment';
import { DatePickerField } from '../util/DatePickerField.tsx';
import { FloatingLabel } from 'react-bootstrap';

interface IProps {
    value: IPickup;
    onChange: (e: ChangeEvent<any>) => any;
    remove: () => any;
    index: number;
    deliveryDate: string;
    asSingle?: boolean;

    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
}

export function PickupLocationBuilderComponent({
    value,
    onChange,
    index,
    errors,
    touched,
    deliveryDate,
    asSingle
}: IProps) {
    const locationError = getIn(errors, `${asSingle ? '' : `pickups[${index}].`}location`, errors);
    const locationTouched = getIn(touched, `${asSingle ? '' : `pickups[${index}].`}location`, touched);
    const startTimeError = getIn(errors, `${asSingle ? '' : `pickups[${index}].`}startTime`, errors);
    const startTimeTouched = getIn(touched, `${asSingle ? '' : `pickups[${index}].`}startTime`, touched);
    const endTimeError = getIn(errors, `${asSingle ? '' : `pickups[${index}].`}endTime`, errors);
    const endTimeTouched = getIn(touched, `${asSingle ? '' : `pickups[${index}].`}endTime`, touched);
    return (
        <div>
            <Form.Group>
                {/*<Form.Label>Location</Form.Label>*/}
                {/*<InputGroup>*/}
                <FloatingLabel label='Pickup Location'>
                    <Form.Control
                        placeholder='Location'
                        name={`${asSingle ? '' : `pickups[${index}].`}location`}
                        value={value.location}
                        onChange={onChange}
                        isInvalid={locationTouched === true && !!locationError && typeof locationError === 'string'}
                    />
                    <Form.Control.Feedback type='invalid' className='order-last'>
                        {typeof locationError === 'string' && locationError}
                    </Form.Control.Feedback>
                </FloatingLabel>
                {/*<Button variant='outline-danger' onClick={remove}>Remove</Button>*/}
                {/*</InputGroup>*/}
            </Form.Group>
            <div className='d-flex flex-row gap-2 mt-2 justify-content-between'>
                <Form.Group>
                    <div>
                        <Form.Label>Start</Form.Label>
                    </div>
                    <DatePickerField
                        showTimeInput
                        timeIntervals={15}
                        placeholder='Start'
                        minDate={moment(deliveryDate).subtract(1, 'day').toDate()}
                        value={moment(value.startTime).format('DD/MM/YYYY [at] HH:mm')}
                        name={`${asSingle ? '' : `pickups[${index}].`}startTime`}
                    />
                    <Form.Control
                        type='hidden'
                        isInvalid={startTimeTouched && !!startTimeError && typeof startTimeError === 'string'}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {typeof startTimeError === 'string' && startTimeError}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                    <div>
                        <Form.Label>End</Form.Label>
                    </div>
                    <DatePickerField
                        showTimeInput
                        timeIntervals={15}
                        placeholder='End'
                        minDate={new Date(deliveryDate)}
                        value={moment(value.endTime).format('DD/MM/YYYY [at] HH:mm')}
                        name={`${asSingle ? '' : `pickups[${index}].`}endTime`}
                    />
                    <Form.Control
                        type='hidden'
                        isInvalid={endTimeTouched && !!endTimeError && typeof endTimeError === 'string'}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {typeof endTimeError === 'string' && endTimeError}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
        </div>
    );
}
