import DatePicker, { DatePickerProps } from 'react-datepicker';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';

// interface IProps extends ReactDatePickerProps {
//
// }

type Props = Omit<DatePickerProps, 'onChange'>;

export function DatePickerField({ ...props }: Props & FieldHookConfig<string>) {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();
    return (
        // @ts-expect-error typing error for onChange
        <DatePicker
            {...field}
            {...(props as Props)}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => setFieldValue(field.name, val)}
            customInput={<Form.Control />}
        />
    );
}
